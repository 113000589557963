/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.password-prompt {
  background: white;
  justify-self: center;
  /* width: 300px; */
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  place-self: center;
  padding: 30px;
  margin: 0 auto;
}
html {
  background: #000;
  font-family: "Amazon Ember Display", Arial, sans-serif;
  background: url(../public/assets/bg-repeat.jpg) #0b1f54;
  background-repeat: repeat-x;
  background-position: top center;
}
body {
  display: flex;
  align-items: center;
  justify-content: center;
}

button,
input {
  font-family: "Amazon Ember Display", Arial, sans-serif;
}

.logo {
  width: 100%;
  height: 140px;
  margin: 20px 0 0;
  padding: 10px 0;
  background: url(../public/assets/prime_night_lockup.png);
  background-repeat: no-repeat;
  background-size: 300px;
  background-position: center;
  z-index: 1000;
  position: relative;
  filter: drop-shadow(0 0 6px rgba(255, 255, 255, 0.5));
}

.container {
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  width: 100%;
  min-height: 100vh;
  max-width: 600px;
  background: url(../public/assets/bg.jpg) #0050ac;
  background-repeat: no-repeat;
  background-size: 800px;
  background-attachment: fixed;
  background-position: top center;
  justify-content: space-between;
  align-items: center;
}

img.thumbnail {
  max-width: 150px;
  max-height: 150px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.button {
  display: block;
  background: #1798ff;
  color: white;
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  text-decoration: none;
  padding: 10px;
  border-radius: 8px;
  /* add a little shadow that lifts the button up */
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  border: 0;
}

.promo .button {
  background: white;
  color: #1798ff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
}

.product {
  background: white;
  border-radius: 24px;
  padding: 20px 20px;
  display: grid;
  margin: 10px 20px;
  text-align: center;
  filter: blur(0) drop-shadow(0 4px 8px rgba(20, 49, 117, 0.5));
  transition: all ease-out 0.9s;
  text-align: center;
}
.product.locked {
  filter: blur(10px) drop-shadow(0 4px 8px rgba(20, 49, 117, 0.5));
  pointer-events: none;
  transform: scale(0.9);
}

h2 {
  color: #143075;
  font-size: 24px;
  font-weight: bold;
  /* width: 100%; */
}

.products {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0;
}

.product {
  display: grid;
  grid-template-columns: 120px 1fr;
  grid-template-rows: repeat(3, "fit-content");
  grid-column-gap: 0px;
  grid-row-gap: 10px;
}

.product-thumbnail-wrapper {
  grid-area: 1 / 1 / 3 / 2;
}
.product-name-wrapper {
  grid-area: 1 / 2 / 3 / 3;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-right: 10px;
}
.product-details-wrapper {
  grid-area: 2 / 2 / 3 / 3;
}
.product-button-wrapper {
  grid-area: 3 / 1 / 4 / 3;
}
.product-name {
  text-align: center;
  /* flex-grow: 1; */
  margin: 0 20px 10px;
  align-self: center;
  padding: 0;
  font-size: 28px;
}
.product-discount {
  font-weight: bold;
}
.banner {
  background: #1a98ff;
  text-align: center;
  padding: 12px 0;
  margin: 16px 0;
  color: #fff;
  position: sticky;
  z-index: 10;
  top: 0;
  font-size: 28px;
  filter: blur(0) drop-shadow(0 3px 4px rgba(20, 49, 117, 0.25));
}

img.prime-night {
  width: 100px;
  /* height: 40px; */
  display: block;
  margin: 0 20px;
}

.coupon-instructions {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 8px;
}

header {
  padding-bottom: 10px;
  /* position: sticky; */
  /* top: -120px; */
  z-index: 5;
  background: url(../public/assets/bg.jpg) #0050ac;
  background-repeat: no-repeat;
  background-size: 800px;
  background-attachment: fixed;
  background-position: top center;
}

.coupon-component {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: #eee;
  margin-bottom: 10px;
  position: relative;
  border-radius: 8px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.coupon-code {
  font-size: 18px;
  text-align: center;
  width: 100%;
}

.coupon-component button {
  border: 0;
  display: block;
  background: #91f6fe;
  color: #232f3e;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
  white-space: nowrap;
}

.coupon-component .confirmation-message {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: #6ef0c2;
  color: #232f3e;
  border-radius: 8px;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.coupon-component .confirmation-message.show {
  opacity: 1;
}

.coupon-component .confirmation-message.hide {
  opacity: 0;
}

.thumbnail {
  margin-bottom: 10px;
  aspect-ratio: 1 / 1;
  margin-left: 10px;
}

img.thumbnail {
  max-width: 100%;
}

footer {
  justify-self: end;
  padding: 8px;
  /* background: #143075; */
  color: #fff;
  font-size: 12px;
  text-align: center;
  margin-top: 20px;
}

footer p {
  margin: 10px;
}

footer a {
  color: #91f6fe;
}

.promo {
  background: #1798ff;
  color: #fff;
  font-weight: bold;
  text-align: center;
  border-radius: 20px;
  margin: 20px 20px 0;
  font-size: 30px;
  padding: 20px;
  display: flex;
  min-height: 160px;
  justify-content: center;
  align-items: center;
  width: auto;
  align-self: center;
  flex-direction: column;
  gap: 20px;
  text-decoration: none;
}

.video-promo {
  padding: 20px;
}

.promo p {
  font-size: 24px;
  font-weight: normal;
}

.container > div {
  display: flex;
  flex-direction: column;
}
